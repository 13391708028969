body {
  overflow: hidden;
}

#react-root > .ui.container {
    width: unset!important;
    /* margin-left: unset!important;
    margin-right: unset!important; */
    margin-left: 150px!important;
    margin-right: 150px!important;
}
.card-cite {
  font-size: 15px;
}
.search-dropdown {
  /* text-align: center; */
  margin-top: -10px;
}
.underline {
  text-decoration: underline;
}
.logo-card {
  font-weight: 200;
  color:#2185d0;
}
.card-view{
  flex-direction: unset!important;
}
.card-view>.extra {
  display: flex;
  flex-direction: column!important;
  justify-content: center!important;
  align-content: space-between!important;
  flex-direction: column!important;
}
.tubpane-header {
  /* margin-top: -32px!important; */
}

.ui.card>.content>.description, .ui.cards>.card>.content>.description {
  clear: both;
  color: rgba(0,0,0,.85)!important;
  margin-bottom: .7em;
  margin-top: .4em!important;
  font-weight: 600!important;
  font-size: 1.2em;
}
/* .search-pane .scroll {
  overflow-y:scroll;
  height: 69vh;
} */
/* .container.app-container {
  width: inherit !important;
  margin-left: 5em !important;
  margin-right: 5em !important;
} */
/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */

/* .card {
	width: 100%;
} */
